import React from 'react'
import { Helmet } from 'react-helmet';
import { INFO_THEME_IMAGE_KIDS } from '../components/infoblock'

const title = `Wir freuen uns auf deinen Besuch!`
const myStyle = {
  backgroundColor: 'white',
  color: 'black'
}
const content = (
  <div>
    <Helmet>
      <link rel="stylesheet" type="text/css" href="https://pretix.eu/demo/democon/widget/v1.css" crossorigin />
      <script type="text/javascript" src="https://pretix.eu/widget/v1.en.js" async crossorigin></script>
    </Helmet>
    <p>Melde dich jetzt an und nimm an der kommenden UnKonf teil!</p>
    <pretix-widget style={myStyle} event="https://pretix.eu/speakonf/speakonf25-2/"></pretix-widget>
    <noscript>
      <div class="pretix-widget">
        <div class="pretix-widget-info-message">
          JavaScript is disabled in your browser. To access our ticket shop without JavaScript,
          please <a target="_blank" href="https://pretix.eu/speakonf/speakonf25-2/">click here</a>.
        </div>
      </div>
    </noscript>
  </div>
)
const action = null

export const CONTENT_INFOBLOCK_APPRECIATION_ANCHOR = 'anmeldung'
export const CONTENT_INFOBLOCK_APPRECIATION = {
  title,
  content,
  action,
  theme: INFO_THEME_IMAGE_KIDS,
  anchorLink: CONTENT_INFOBLOCK_APPRECIATION_ANCHOR,
}
